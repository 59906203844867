import React, { FC } from 'react'
import dayjs from 'dayjs'
import { User, userAction } from '../../../../../../slices/users'
import { Paragraph } from '../../../../../../ui-kit/components/Typography/Typography'
import { USER_ROLE } from '../../../../../../types'
import styles from './styles.module.scss'
import TooltipMenu from '../../../../../../components/TooltipMenu'
import { Avatar } from '@mui/material'
import Icons from '../../../../../../assets/icons'
import { Building } from '../../../../../../slices/building'
import { generatePath, useNavigate } from 'react-router-dom'
import { TextButton } from '../../../../../../ui-kit/components/Button'
import { useAppDispatch } from '../../../../../../hooks'
import { WorkOrderStatus } from '../../../../../../components/NewWorkOrder/config'
import StatusBadge from '../../../../../../components/StatusBadge'
import { getColorUserStatus } from '../../../../helpers'

type Props = {
    nameColumn: string
    user: { [key: string]: any }
}
const TableBodyItem: FC<Props> = ({ nameColumn, user }) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const handleClickBuilding = (building: Building) => {
        const path = generatePath('/buildings/:id', { id: building.id })
        navigate(path)
    }

    if (!user) {
        return null
    }

    switch (nameColumn) {
        case 'name': {
            const firstName = (user as User).firstName
            const lastName = (user as User).lastName

            const name = firstName + ' ' + lastName
            return (
                <Paragraph size="s" weight="tiny">
                    {name}
                </Paragraph>
            )
        }

        case 'region': {
            const region = (user as User).region
            return (
                <Paragraph size="s" weight="tiny">
                    {region?.name}
                </Paragraph>
            )
        }
        case 'creationDate': {
            const creationDate = (user as User).creationDate

            return (
                <Paragraph size="s" weight="tiny">
                    {dayjs(creationDate).format('MM/DD/YY h:mm A')}
                </Paragraph>
            )
        }
        case 'lastLogin': {
            const lastLogin = (user as User).lastLogin

            return (
                <Paragraph size="s" weight="tiny">
                    {dayjs(lastLogin).format('MM/DD/YY h:mm A')}
                </Paragraph>
            )
        }
        case 'lastActivity': {
            const lastActivity = (user as User).lastActivity

            return (
                <Paragraph size="s" weight="tiny">
                    {dayjs(lastActivity).format('MM/DD/YY h:mm A')}
                </Paragraph>
            )
        }

        case 'status': {
            const status = (user as User).status

            //Todo: Refactor when status will be not undefined
            if (!status) {
                return (
                    <Paragraph size="s" weight="tiny">
                        -
                    </Paragraph>
                )
            }

            return (
                <div className={styles.flexWrap}>
                    <StatusBadge color={getColorUserStatus(user.status)} text={user.status} />
                </div>
            )
        }

        // case 'teams': {
        //     const status = (user as User)
        //
        //     return (
        //         <div className={styles.flexWrap}>
        //             <UserStatusBadge status={status} />
        //         </div>
        //     )
        // }

        case 'openWo': {
            const role = (user as User).role
            let openWo = '-'

            switch (role) {
                case USER_ROLE.TECHNICIAN:
                    openWo = (user as User).TechActiveWorkOrdersNumber.toString()
                    break
                case USER_ROLE.REQUESTOR:
                    openWo = (user as User).ReqActiveWorkOrdersNumber.toString()
                    break
                case USER_ROLE.SUPERVISOR:
                    openWo = (user as User).SupervisorActiveWorkOrdersNumber.toString()
                    break
            }

            return (
                <TextButton
                    text={openWo}
                    className={styles.menuItem}
                    onClick={(e) => {
                        e.stopPropagation()
                        dispatch(
                            userAction.setWorkOrdersLayout({
                                userId: user.id,
                                statuses: [
                                    WorkOrderStatus.NEW,
                                    WorkOrderStatus.IN_PROGRESS,
                                    WorkOrderStatus.ON_HOLD,
                                    WorkOrderStatus.PENDING_REVIEW,
                                ],
                                title: 'Active Work Orders',
                            }),
                        )
                    }}
                />
            )
        }

        case 'pastDueWo': {
            const role = (user as User).role
            let pastDueWo = '-'

            switch (role) {
                case USER_ROLE.TECHNICIAN:
                    pastDueWo = (user as User).TechOverdueWorkOrdersNumber.toString()
                    break
                case USER_ROLE.SUPERVISOR:
                    pastDueWo = (user as User).SupervisorOverdueWorkOrdersNumber.toString()
                    break
            }

            return (
                <TextButton
                    text={pastDueWo}
                    className={styles.menuItem}
                    onClick={(e) => {
                        e.stopPropagation()
                        dispatch(
                            userAction.setWorkOrdersLayout({
                                userId: user.id,
                                isOverdue: true,
                                title: 'Past Due Work Orders',
                            }),
                        )
                    }}
                />
            )
        }

        case 'totalWo': {
            const role = (user as User).role
            let totalWo = '-'

            switch (role) {
                case USER_ROLE.TECHNICIAN:
                    totalWo = (user as User).TechTotalWorkOrdersNumber.toString()
                    break
                case USER_ROLE.SUPERVISOR:
                    totalWo = (user as User).SupervisorTotalWorkOrdersNumber.toString()
                    break
                case USER_ROLE.REQUESTOR:
                    totalWo = (user as User).ReqTotalWorkOrdersNumber.toString()
                    break
            }

            return (
                <TextButton
                    text={totalWo}
                    className={styles.menuItem}
                    onClick={(e) => {
                        e.stopPropagation()
                        dispatch(
                            userAction.setWorkOrdersLayout({
                                userId: user.id,
                                title: 'Total Work Orders',
                            }),
                        )
                    }}
                />
            )
        }

        case 'assignedBuildings': {
            const assignedBuildings = (user as User).buildingAssignments

            if (assignedBuildings.length === 0) {
                return (
                    <Paragraph size="s" weight="tiny">
                        {assignedBuildings.length}
                    </Paragraph>
                )
            }

            return (
                <TooltipMenu
                    classesRoot={styles.menuItem}
                    button={
                        <Paragraph className={styles.buildingsTooltip} size="s" weight="tiny">
                            {assignedBuildings.length}
                        </Paragraph>
                    }
                >
                    <Paragraph className={styles.tooltipHeader} position="center" size="s" weight="semi-bold">
                        Buildings Assigned
                    </Paragraph>
                    {assignedBuildings.map((building) => (
                        <div
                            key={building.id}
                            className={styles.buildingsTooltipRow}
                            onClick={() => handleClickBuilding(building)}
                        >
                            <Avatar
                                src={building.avatar?.url}
                                sx={{
                                    width: '30px',
                                    height: '30px',
                                    background: 'transparent',
                                }}
                                variant="rounded"
                                imgProps={{ crossOrigin: 'anonymous' }}
                            >
                                <Icons.Building />
                            </Avatar>
                            <div className={styles.nameAddress}>
                                <Paragraph size="s" weight="semi-bold">
                                    {building.name}
                                </Paragraph>

                                <Paragraph size="s" color="grey">
                                    {building.address}
                                </Paragraph>
                            </div>
                        </div>
                    ))}
                </TooltipMenu>
            )
        }

        default:
            return (
                <Paragraph size="s" weight="tiny">
                    {user[nameColumn] || '-'}
                </Paragraph>
            )
    }
}

export default TableBodyItem
