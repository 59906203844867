import React, { useEffect, useState } from 'react'
import s from './styles.module.scss'
import Icons from 'assets/icons'
import TooltipMenu from '../TooltipMenu'
import Notifications from './components/Notifications'
import Badge from '@mui/material/Badge'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getNotificationsCount } from '../../api/notifications'
import sendNotification from '../../lib/notification'
import { NOTIFICATION_STATUS } from '../../types'
import { currentUserSelect } from '../../store/selectors'
import { Avatar } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { ROUTES } from 'routes/const'
import cn from 'classnames'

export const InternalHeader = () => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(currentUserSelect)

    const [notificationsCount, setNotificationsCount] = useState(0)
    const [isOpenNotifications, setIsOpenNotifications] = useState(false)

    const getNotificationsCountData = async () => {
        try {
            const res = await dispatch(getNotificationsCount()).unwrap()
            setNotificationsCount(res.unreadCount)
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }
    }

    useEffect(() => {
        ;(async () => {
            await getNotificationsCountData()
        })()
    }, [])

    return (
        <div className={s.internalHeader}>
            <div className={s.alert}>
                {/*<Icons.AlertSVG />*/}
                {/*<p>Create System Alert</p>*/}
                <TooltipMenu
                    onClose={() => setIsOpenNotifications(false)}
                    onOpen={() => setIsOpenNotifications(true)}
                    button={
                        <Badge
                            badgeContent={notificationsCount}
                            color="error"
                            sx={{
                                '& .MuiBadge-badge': {
                                    height: '15px',
                                    paddingInline: '4px',
                                    fontSize: '12px',
                                },
                            }}
                        >
                            <Icons.BellSVG />
                        </Badge>
                    }
                    isClosed={!isOpenNotifications}
                >
                    <Notifications
                        open={isOpenNotifications}
                        count={notificationsCount}
                        updateCount={getNotificationsCountData}
                        onClose={() => setIsOpenNotifications(false)}
                    />
                </TooltipMenu>
            </div>
            <NavLink to={ROUTES.PROFILE} className={s.link}>
                {({ isActive }) => (
                    <div className={cn(s.profile, isActive ? s.active : '')}>
                        {user?.firstName + ' ' + user?.lastName}

                        <Avatar
                            alt={user?.firstName}
                            src={user?.avatar?.url}
                            sx={{ width: 24, height: 24 }}
                            variant="rounded"
                            imgProps={{ crossOrigin: 'anonymous' }}
                        />
                    </div>
                )}
            </NavLink>
        </div>
    )
}
