import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Autocomplete } from '@mui/material'
import { getOptions } from 'lib/common'
import useDebounce from 'hooks/useDebounce'
import { useAppDispatch, useAppSelector } from 'hooks'
import { currentUserSelect } from 'store/selectors'
import { CustomTextField } from 'ui-kit/components/CustomTextField'
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete/Autocomplete'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import { CustomShowMore } from 'ui-kit/components/CustomShowMore'
import { getTeamsListForAutocomplete } from 'api/teams'
import { TeamType } from 'slices/team'
import { CommonResponseType } from 'types'
import { TEAMS_PER_PAGE } from 'pages/WorkOrdersPage/components/WorkOrdersTeams/config'
import { CustomRemovedBox } from 'ui-kit/components/CustomRemovedBox'
import { AutocompletePropsType, ItemForAutocompleteType } from '.'
import Icons from 'assets/icons'

import s from './styles.module.scss'

export const TeamAutoComplete = ({
    handleBlur,
    label,
    textFieldClassName,
    errorText,
    textFieldPlaceholder,
    isSearch = false,
    readOnly = false,
    disableClearable = false,
    width = '100%',
    currentValue,
    onChangeCallback,
    onDeleteItemClick,
    multiple,
    idDependence,
}: AutocompletePropsType) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(currentUserSelect)
    const [values, setValues] = useState<TeamType[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)
    const [search, setSearch] = useState('')
    const debouncedSearch = useDebounce(search, 500)

    const onInputChange = (event: any, newInputValue: string) => {
        setSearch(newInputValue)
        setPage(1)
    }

    const disabledCondition = count <= page * TEAMS_PER_PAGE || disableClearable

    const isOptionEqualToValue = (option: ItemForAutocompleteType, value: ItemForAutocompleteType) =>
        option?.value === value?.value

    const onShowMoreClick = (e: React.MouseEvent<HTMLParagraphElement>) => {
        e.preventDefault()
        setPage((prev) => prev + 1)
        getAllValues(page + 1)
    }

    const getAllValues = async (currentPage: number) => {
        setIsLoading(true)
        try {
            const res: CommonResponseType<TeamType> = await dispatch(
                getTeamsListForAutocomplete({
                    page: currentPage,
                    customerId: user?.customerId ?? '',
                    search,
                    regionId: idDependence?.regionId ? (idDependence?.regionId as string) : undefined,
                }),
            ).unwrap()

            setCount(res?.count)
            setValues((prev) => (currentPage === 1 ? res.rows : [...res.rows, ...prev]))
        } catch (error: any) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!user || typeof idDependence === 'undefined' || search) {
            return
        }

        getAllValues(page)
    }, [user, search, JSON.stringify(idDependence)])

    useEffect(() => {
        if (typeof idDependence === 'undefined') {
            return
        }
        if (debouncedSearch) {
            getAllValues(page)
        }
    }, [debouncedSearch, JSON.stringify(idDependence)])

    const getOptionLabel = useCallback((option: ItemForAutocompleteType) => {
        return option?.label ?? ''
    }, [])

    const renderOption = useCallback(
        (props: React.HTMLAttributes<HTMLLIElement>, option: ItemForAutocompleteType) => {
            const currentTeam = values.find((value) => value.id === option?.value)

            if (option.value === 'Not found') {
                return (
                    <li style={{ pointerEvents: 'none' }} {...props}>
                        <Icons.NotFoundSVG color="#848a9b" style={{ marginRight: '.4rem' }} />
                        <Paragraph size={'s'} color="grey">
                            Not found
                        </Paragraph>
                    </li>
                )
            }

            return (
                <li {...props}>
                    {currentTeam?.avatar?.url ? (
                        <img
                            src={currentTeam?.avatar?.url}
                            alt="team-avatar"
                            width={24}
                            height={24}
                            style={{ borderRadius: '50%', marginRight: '.4rem' }}
                            crossOrigin={'anonymous'}
                        />
                    ) : (
                        <Icons.UsersSVG
                            style={{ width: '24px', height: '24px', borderRadius: '50%', marginRight: '.4rem' }}
                        />
                    )}

                    <Paragraph size={'s'} style={{ marginRight: '.4rem' }}>
                        {option?.label}
                    </Paragraph>
                    <div className={s.status}>
                        {!!currentTeam?.workInProgress && (
                            <Paragraph size={'xxs'} className={s.inProgress}>
                                {currentTeam?.workInProgress} In Progress
                            </Paragraph>
                        )}
                        {!!currentTeam?.workOnHold && (
                            <Paragraph size={'xxs'} className={s.onHold}>
                                {currentTeam?.workOnHold} On Hold
                            </Paragraph>
                        )}
                        {!!currentTeam?.workPendingReview && (
                            <Paragraph size={'xxs'} className={s.pending}>
                                {currentTeam?.workPendingReview} Pending Review
                            </Paragraph>
                        )}
                    </div>
                </li>
            )
        },
        [values],
    )

    const renderTags = useCallback(
        (value: ItemForAutocompleteType[], getTagProps: AutocompleteRenderGetTagProps) => {
            if (value?.length === 1) {
                return (
                    <Paragraph
                        size={'s'}
                        style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {value[0]?.label}
                    </Paragraph>
                )
            } else {
                return (
                    <Paragraph size={'s'} style={{ display: 'flex', gap: '.4rem' }}>
                        {value?.length}
                        <Paragraph size={'s'} color={'grey'}>
                            (teams)
                        </Paragraph>
                    </Paragraph>
                )
            }
        },
        [onDeleteItemClick],
    )

    const getPlaceholder = useMemo(() => {
        setSearch('')
        if (multiple) {
            return currentValue?.length ? '' : textFieldPlaceholder
        }
        return !Array.isArray(currentValue) && currentValue?.value ? '' : textFieldPlaceholder
    }, [multiple, currentValue])

    return (
        <>
            <Autocomplete
                value={currentValue}
                readOnly={readOnly}
                onChange={(event, newValue) => {
                    onChangeCallback(newValue as ItemForAutocompleteType[] & ItemForAutocompleteType, values)
                    setSearch('')
                }}
                multiple={multiple}
                inputValue={search}
                onInputChange={onInputChange}
                options={
                    values?.length ? getOptions(values, 'id', 'name') : [{ value: 'Not found', label: 'Not found' }]
                }
                freeSolo
                renderTags={renderTags}
                renderOption={renderOption}
                getOptionLabel={(option) => getOptionLabel(option as ItemForAutocompleteType)}
                renderInput={(params) => (
                    <CustomTextField
                        label={label}
                        onBlur={handleBlur}
                        params={params}
                        errorText={errorText}
                        loading={isLoading}
                        className={textFieldClassName}
                        placeholder={getPlaceholder}
                        isSearch={isSearch}
                    />
                )}
                disableCloseOnSelect={multiple}
                filterOptions={(x) => x}
                limitTags={1}
                style={{ width }}
                loading={isLoading}
                isOptionEqualToValue={isOptionEqualToValue}
                disableClearable={disableClearable}
                PaperComponent={({ children }) => (
                    <CustomShowMore
                        onShowMoreClick={onShowMoreClick}
                        children={children}
                        text={'Show more'}
                        disabled={disabledCondition}
                    />
                )}
            />

            {onDeleteItemClick &&
                multiple &&
                currentValue &&
                currentValue.length > 1 &&
                currentValue.map((item) => {
                    return (
                        <CustomRemovedBox
                            id={item?.value ?? ''}
                            title={item?.label ?? ''}
                            onDeleteClick={onDeleteItemClick}
                        />
                    )
                })}
        </>
    )
}
