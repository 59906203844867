import { User } from '../../../../../../slices/users'
import { CardColumnItem, CardContainer, CardFlexRow, CardHeader } from '../../../../../../components/CardsContentView'
import dayjs from 'dayjs'
import React from 'react'
import { Avatar } from '@mui/material'
import styles from './styles.module.scss'
import { noop } from '../../../../../../lib/common'

type Props = {
    user: User
    onClick?: (user: User) => void
}
const CardViewItem = ({ user, onClick = noop }: Props) => {
    return (
        <CardContainer onClick={() => onClick(user)}>
            <CardHeader
                image={
                    <Avatar
                        alt={user.firstName}
                        src={user.avatar?.url}
                        sx={{ width: 45, height: 45 }}
                        variant="rounded"
                        imgProps={{ crossOrigin: 'anonymous' }}
                    />
                }
                name={user.firstName + ' ' + user.lastName}
                registered={dayjs(user.creationDate).format('MM/DD/YY hh:mmA')}
                description={`Last Login: ${user.lastLogin ? dayjs(user.lastLogin).format('MM/DD/YY hh:mmA') : '-'}`}
            />

            <CardFlexRow>
                <CardColumnItem title="Region:" value={user.region?.name || '-'} />
                <CardColumnItem title="Type:" value={user.type} />
            </CardFlexRow>
            <CardFlexRow>
                <CardColumnItem className={styles.emailItem} title="Email:" value={user.email} />
                <CardColumnItem title="Phone:" value={user.phone} />
            </CardFlexRow>
        </CardContainer>
    )
}

export default CardViewItem
