import styles from './styles.module.scss'
import { Paragraph } from '../../ui-kit/components/Typography/Typography'
import { Avatar } from '@mui/material'
import React from 'react'
import { User } from '../../slices/users'
import CloseButton from '../CloseButton'
import { CallPhoneSVG, CallEmailSVG } from '../../assets/customer-icons'
import CustomInput from '../../ui-kit/components/CustomInput'
import { capitalized } from '../../lib/common'
import SaveCancelPanel from '../SaveCancelPanel'
import { generatePath, useNavigate } from 'react-router-dom'
import useCrudUserPermission from '../../pages/UsersTenantsPage/hooks/useCrudUserPermission'
import StatusBadge from '../StatusBadge'
import { getColorUserStatus } from '../../pages/UsersTenantsPage/helpers'

type Props = {
    user: User | null
    onClose: () => void
}
const UserContactInfoLayout = ({ user, onClose }: Props) => {
    const navigate = useNavigate()

    const isCrud = useCrudUserPermission(user?.role)

    if (!user) {
        return null
    }

    const handleClickViewInfo = () => {
        const path = generatePath('/users/user/:id', { id: user.id })
        navigate(path)
    }

    return (
        <div className={styles.root}>
            <div className={styles.content}>
                <CloseButton onClose={onClose} />

                <div className={styles.nameWrap}>
                    <Paragraph size="md" weight="semi-bold" position="center">
                        {user.firstName + ' ' + user.lastName}
                    </Paragraph>
                    <StatusBadge color={getColorUserStatus(user.status)} text={user.status} />{' '}
                </div>

                <Avatar
                    alt={user.firstName + ' ' + user.lastName}
                    src={user.avatar?.url}
                    variant="rounded"
                    sx={{
                        width: 180,
                        height: 180,
                        borderRadius: '20px',
                    }}
                    imgProps={{ crossOrigin: 'anonymous' }}
                />

                <div className={styles.contactButtonWrap}>
                    <a className={styles.contactButton} href={`tel:${user.phone}`}>
                        <div className={styles.contactButtonContent}>
                            <CallPhoneSVG />
                            <Paragraph size="s" weight="tiny">
                                Call
                            </Paragraph>
                        </div>
                    </a>
                    <a className={styles.contactButton} href={`mailto:${user.email}`}>
                        <div className={styles.contactButtonContent}>
                            <CallEmailSVG />
                            <Paragraph size="s" weight="tiny">
                                Email
                            </Paragraph>
                        </div>
                    </a>
                </div>

                <div className={styles.infoWrap}>
                    <CustomInput
                        readOnly
                        label={'User role'}
                        variant="white-grey"
                        value={capitalized(user.role || '')}
                    />
                    <CustomInput readOnly label={'User type'} variant="white-grey" value={user.type} />
                    <CustomInput readOnly label={'Title'} variant="white-grey" value={user.title} />
                    <CustomInput readOnly label={'Phone'} variant="white-grey" value={user.phone} />
                    <CustomInput readOnly label={'Email'} variant="white-grey" value={user.email} />
                </div>
            </div>
            {isCrud && (
                <div className={styles.saveWrap}>
                    <SaveCancelPanel saveButtonText="View full information" onSave={handleClickViewInfo} />
                </div>
            )}
        </div>
    )
}

export default UserContactInfoLayout
